(function (global) {
	function getRandomValues(polyfillBuffer) {
		if (
			!(
				polyfillBuffer instanceof Uint8Array ||
				polyfillBuffer instanceof Uint16Array ||
				polyfillBuffer instanceof Uint32Array
			)
		) {
			throw new TypeError(
				'The provided buffer is not an instance of Uint8Array, Uint16Array, or Uint32Array.'
			);
		}

		for (let i = 0; i < polyfillBuffer.length; i++) {
			// Math.random() returns a floating-point, pseudo-random number in the range 0 to less than 1
			// We then multiply it by the maximum value of the array element type to get a range between 0 and maxValue
			const maxValue = 256 ** polyfillBuffer.BYTES_PER_ELEMENT - 1;
			polyfillBuffer[i] = Math.floor(Math.random() * (maxValue + 1));
		}
		return polyfillBuffer;
	}

	if (!global.crypto) {
		global.crypto = {};
	}

	if (!global.crypto.getRandomValues) {
		global.crypto.getRandomValues = getRandomValues;
	}
})(typeof window !== 'undefined' ? window : global);
